@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #eae7dc;
}

.icon-link {
  color: #d8c3a5;
  transition: transform 0.3s, color 0.3s;
}

.icon-link:hover {
  animation: wiggle 0.5s;
  color: #e85a4f;
}

@keyframes wiggle {
  0% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0);
  }
}



